<template>
  <div class="main">
    <div style="padding-top: 20px; background: #fff">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>求职信息</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>
    </div>

    <el-form
      ref="form"
      label-position="left"
      :model="form"
      label-width="100px"
      :required-asterisk="true"
      :rules="formRules"
    >
      <div class="formBox">
        <div class="essentialInformation">
          <div class="title">
            <div class="blueBgc">
              <i
                class="iconfont icon-jibenxinxi"
                style="font-size: 22px; color: #fff"
              ></i>
            </div>
            <b>基本信息</b>
          </div>
          <div class="formItem">
            <el-form-item prop="name" label="姓名：">
              <el-input v-model="form.name" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item prop="sex" label="性别：">
              <el-select v-model="form.sex" placeholder="请选择性别">
                <el-option
                  v-for="item in sex"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="wasBornDate" label="出生年月：">
              <el-date-picker
                type="date"
                placeholder="选择时间"
                v-model="form.wasBornDate"
                style="width: 100%"
                :picker-options="pickerOptions"
                @change="getWasBornDate"
              ></el-date-picker>
            </el-form-item>
            <el-form-item prop="age" label="年龄：">
              <el-input v-model="form.age" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="title" label="专业职称：">
              <el-select v-model="form.title" placeholder="请选择">
                <el-option
                  v-for="item in jobUserType"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="nation" label="民族：">
              <el-input v-model="form.nation" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="expectSalary" label="期望薪资：">
              <el-select
                v-model="form.expectSalary"
                @change="getSalary"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in jobPay"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item prop="city" label="期望地区：">
              <el-cascader
                v-model="form.city"
                :props="cityProps"
                :options="provincialList"
                @change="handleChange"
                :show-all-levels="false"
                placeholder="请选择城市"
              ></el-cascader>
            </el-form-item> -->
            <!-- <el-form-item label="期望地区：">
              <el-select
                v-model="form.province"
                placeholder="请选择"
                @change="chooseProvincial"
              >
                <el-option
                  v-for="item in provincialList"
                  :key="item.PROVINCE_CODE"
                  :label="item.SHORT_NAME"
                  :value="item.PROVINCE_CODE"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="form.city"
                placeholder="请选择"
                @change="chooseCity"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.CITY_CODE"
                  :label="item.SHORT_NAME"
                  :value="item.CITY_CODE"
                >
                </el-option>
              </el-select>
              <el-select v-model="form.area" placeholder="请选择">
                <el-option
                  v-for="item in areaList"
                  :key="item.AREA_CODE"
                  :label="item.SHORT_NAME"
                  :value="item.AREA_CODE"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="expectPost" label="求职意向：">
              <el-cascader
                v-model="form.expectPost"
                :options="tradeTypeList"
                :show-all-levels="false"
                :props="typeProps"
                @change="chooseTradeType"
                placeholder="请选择"
              ></el-cascader>
            </el-form-item> -->
          </div>
          <div class="formItem2">
            <el-form-item label="期望地区：" prop="area">
              <el-select
                v-model="form.province"
                placeholder="请选择省份"
                @change="chooseProvincial"
              >
                <el-option
                  v-for="item in provincialList"
                  :key="item.PROVINCE_CODE"
                  :label="item.SHORT_NAME"
                  :value="item.PROVINCE_CODE"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="form.city"
                placeholder="请选择城市"
                @change="chooseCity"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.CITY_CODE"
                  :label="item.SHORT_NAME"
                  :value="item.CITY_CODE"
                >
                </el-option>
              </el-select>
              <el-select v-model="form.area" placeholder="请选择地区">
                <el-option
                  v-for="item in areaList"
                  :key="item.AREA_CODE"
                  :label="item.SHORT_NAME"
                  :value="item.AREA_CODE"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="expectPost" label="求职意向：">
              <el-cascader
                v-model="form.expectPost"
                :options="tradeTypeList"
                :show-all-levels="false"
                :props="typeProps"
                @change="chooseTradeType"
                placeholder="请选择"
              ></el-cascader>
            </el-form-item>
          </div>
        </div>

        <div class="workExperience">
          <div class="title">
            <div class="blueBgc">
              <i
                class="iconfont icon-gongjuxiang"
                style="font-size: 20px; color: #fff"
              ></i>
            </div>
            <b><span style="color: red">*</span>工作经历</b>
          </div>
          <div class="formItem">
            <el-form-item prop="jobInfo">
              <el-input
                v-model="form.jobInfo"
                placeholder="描述你的近几年的工作经历、工作单位以及工作时间"
                type="textarea"
                 maxlength="500"
                 show-word-limit
                :autosize="{ minRows: 6 }"
              ></el-input>
            </el-form-item>
          </div>
        </div>

        <div class="selfIntroduction">
          <div class="title">
            <div class="blueBgc">
              <i
                class="iconfont icon-jieshao"
                style="font-size: 20px; color: #fff"
              ></i>
            </div>
            <b><span style="color: red">*</span>自我介绍</b>
          </div>
          <div class="formItem">
            <el-form-item prop="myIntro">
              <el-input
                v-model="form.myIntro"
                placeholder="介绍自己说明自己的最大优势，让企业看到自己的闪光点"
                type="textarea"
                maxlength="300"
                 show-word-limit
                :autosize="{ minRows: 6 }"
              ></el-input>
            </el-form-item>
          </div>
        </div>

        <div class="vocationalSkills">
          <div class="title">
            <div class="blueBgc">
              <i
                class="iconfont icon-_zhichangjineng"
                style="font-size: 22px; color: #fff"
              ></i>
            </div>
            <b>职业技能</b>
          </div>
          <div class="formItem">
            <span style="color: #666">证书：</span>
            <div class="certificateBox">
              <div class="certificateItem">
                <span>学历证书</span>
                <img
                  v-if="certificates.degree_certificate"
                  :src="$imgUrl(certificates.degree_certificate)"
                  alt=""
                />
                <img v-else src="@/assets/img/null.png" alt="" />
                <!-- <img
                  :src="certificates.degree_certificate ? $imgUrl(certificates.degree_certificate) : '@/assets/img/null.png'"
                  alt=""
                /> -->
              </div>
              <div class="certificateItem">
                <span>资格证书</span>
                <img
                  v-if="certificates.qualification_certificate"
                  :src="$imgUrl(certificates.qualification_certificate)"
                  alt=""
                />
                <img v-else src="@/assets/img/null.png" alt="" />
                <!-- <img
                  :src="
                    certificates.qualification_certificate
                      ? $imgUrl(certificates.qualification_certificate)
                      : '@/assets/img/null.png'
                  "
                  alt=""
                /> -->
              </div>
              <div class="certificateItem">
                <span>职称证书</span>
                <img
                  v-if="certificates.title_certificate"
                  :src="$imgUrl(certificates.title_certificate)"
                  alt=""
                />
                <img v-else src="@/assets/img/null.png" alt="" />
                <!-- <img
                  :src="
                    certificates.title_certificate
                      ? $imgUrl(certificates.title_certificate)
                      : '@/assets/img/null.png'
                  "
                  alt=""
                /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-form>

    <div class="btnBox">
      <el-button
        type="primary"
        :loading="saveFlag"
        :disabled="saveFlag"
        @click="save"
        >保存</el-button
      >
      <el-button type="info" @click="dialogVisible = true">取消</el-button>
    </div>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>退出后信息将不会保存，是否退出？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="goOutResume">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { jobPostType } from '@/api/position/position'
import {
  getJobUserAuth,
  jobInfoAdd,
  jobInfoDetail,
  jobInfoEdit,
} from '@/api/jobInformation/jobInformation'
import { getProvincial, getCity, getArea } from '@/api/index/index'
import { detailResume } from '@/api/personalCenter/personalCenter'
import { subtractEquityNum } from '@/api/equity/equity.js'
import { queryExists } from '@/api/jobsensitivechar/jobSensitiveCharManage'
import { detailTeam } from '@/api/team/team'
export default {
  name: '',
  data() {
    return {
      id: '', //求职信息id 编辑用
      imgUrl: '',
      saveFlag: false, //保存按钮，避免多次保存
      dict: [],
      jobVipEquity: [],
      sex: [],
      jobPay: [], //薪资
      people: [],
      jobUserType: [], //人员构成
      provincialList: [], //省份列表
      cityList: [], //城市列表
      areaList: [], //区域列表
      tradeTypeList: [], //工种列表
      typeProps: {
        value: 'id',
        label: 'title',
        children: 'children',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      form: {
        jobUserId: '',
        name: '', //名字
        sex: '', //性别
        wasBornDate: null, //出生年月
        age: '', //年龄
        title: '', //职称
        nation: '', //民族
        expectSalary: '', //期望薪资
        province: '', //省份
        city: '', //城市
        area: '', //区域
        expectPost: '', //期望职位
        jobInfo: '', //工作经历
        myIntro: '', //自我简介
      },
      formRules: {
        name: [{ required: true, message: '请输入姓名' }],
        sex: [{ required: true, message: '请选择性别' }],
        wasBornDate: [
          { type: 'string', required: true, message: '请选择日期' },
        ],
        age: [{ required: true, message: '请输入年龄' }],
        title: [{ required: true, message: '请选择专业职称' }],
        nation: [{ required: true, message: '请输入民族' }],
        expectSalary: [{ required: true, message: '请选择期望薪资' }],
        area: [{ required: true, message: '请选择工作城市' }],
        expectPost: [{ required: true, message: '请选择求职意向' }],
        jobInfo: [{ required: true, message: '请填写工作经历' }],
        myIntro: [{ required: true, message: '请填写自我简介' }],
      },
      dialogVisible: false,
      certificates: {}, //证书信息
      formFlag: false, //表单验证开关
      resume: {}, //简历详情
      jobInfo: {}, //求职信息
      title: [],
      text01: '',
      boo: false,
    }
  },
  watch: {},
  props: {},
  components: {},
  computed: {},
  created() {
    this.id = this.$route.query.id ? this.$route.query.id : ''
    this.dict = JSON.parse(localStorage.getItem('dict'))
    this.jobVipEquity = this.dict.filter(
      (i) => i.code == 'job_vip_equity'
    )[0].children
    this.sex = this.dict.filter((i) => i.code == 'sex')[0].children
    // this.sex.splice(this.sex.length - 1, 1)
    this.jobPay = this.dict.filter((i) => i.code == 'job_pay')[0].children
    this.jobUserType = this.dict
      .filter((i) => i.code == 'job_user_type')[0]
      .children.filter((i) => i.code != 2)
    // 修改查询求职信息  添加查简历
    this.id ? this.getJobInfoDetail() : this.getDetailResume()
    // this.id ? this.getJobInfoDetail() : this.getJobUserAuthForId()
    // this.getDetailResume()
    // this.getJobInfoDetail()
    this.getJobTradeType()
    // this.cityProps = {
    //   value: 'PROVINCE_CODE',
    //   label: 'SHORT_NAME',
    //   children: 'children',
    //   lazy: true,
    //   lazyLoad: (node, resolve) => {
    //     const { level } = node
    //     if (level == 0) {
    //       getProvincial().then(res => {
    //         const city = res.data
    //         resolve(city)
    //       })
    //     }
    //     if (level == 1) {
    //       getCity({ PROVINCE_CODE: node.value }).then(res => {
    //         let arr = []
    //         res.data.forEach(i => {
    //           const obj = {
    //             PROVINCE_CODE: i.CITY_CODE,
    //             SHORT_NAME: i.SHORT_NAME,
    //             leaf: true,
    //           }
    //           arr.push(obj)
    //         })
    //         const city = arr
    //         resolve(city)
    //       })
    //     }
    //   },
    // }
    this.getJobUserAuthForId()

    this._getProvincial()
  },
  mounted() {},
  methods: {
    // 查询简历
    getDetailResume() {
      detailResume({ JobUserId: localStorage.getItem('userId') }).then(
        (res) => {
          if (res.success && res.data) {
            this.resume = res.data
            console.log(this.resume)
            this.form.name = this.resume.name
            // this.form.sex = this.resume.sex == 1 ? '男' : '女'
            this.form.sex = String(this.resume.sex)
            this.form.wasBornDate = this.resume.wasBornDate
            this.form.age = this.resume.age
            this.form.nation = this.resume.nation
            this.form.expectSalary = this.resume.expectSalary
          }
        }
      )
    },
    // 查询求职信息
    getJobInfoDetail() {
      jobInfoDetail({ id: this.id }).then((res) => {
        this.jobInfo = res.data
        this.form.jobUserId = this.jobInfo.jobUserId
        this.form.name = this.jobInfo.name
        // this.form.sex = this.jobInfo.sex == 1 ? '男' : '女'
        this.form.sex = String(this.jobInfo.sex)
        this.form.wasBornDate = this.jobInfo.wasBornDate
        this.form.age = this.jobInfo.age
        this.form.title = this.jobInfo.title
        this.form.nation = this.jobInfo.nation
        this.form.expectSalary = this.jobInfo.expectSalary
        this.form.province = this.jobInfo.province
        this.chooseProvincial(this.jobInfo.province)
        this.form.city = this.jobInfo.city
        this.chooseCity(this.jobInfo.city)
        this.form.area = this.jobInfo.area
        this.form.expectPost = this.jobInfo.expectPost
        this.form.jobInfo = this.jobInfo.jobInfo
        this.form.myIntro = this.jobInfo.myIntro
      })
    },
    // 省份
    _getProvincial() {
      getProvincial().then((res) => {
        console.log(res, '省份')
        this.provincialList = res.data
      })
    },
    // 查找城市
    chooseProvincial(val) {
      this.form.city = ''
      this.form.area = ''
      getCity({ PROVINCE_CODE: val }).then((res) => {
        console.log(res, '城市')
        this.cityList = res.data
      })
    },
    // 查找区域
    chooseCity(val) {
      this.form.area = ''
      getArea({ CITY_CODE: val }).then((res) => {
        this.areaList = res.data
        console.log(this.form)
      })
    },
    // 查询证书
    getJobUserAuthForId() {
      getJobUserAuth().then((res) => {
        this.certificates = res.data
        // this.form.name = this.certificates.name
        // this.form.sex = this.certificates.sex == 1 ? '男' : '女'
        if (this.certificates == null) {
          this.certificates = {}
          let jobUserId = localStorage.getItem('userId')
          detailTeam({ jobUserId }).then((info) => {
            console.log(info.data)
            if (info.data.auditStatus == '2') {
              this.certificates.degree_certificate = info.data.degreeCertificate
              this.certificates.qualification_certificate =
                info.data.qualificationCertificate
              this.certificates.title_certificate = info.data.titleCertificate
            } else {
              this.certificates.degree_certificate = ''
              this.certificates.qualification_certificate = ''
              this.certificates.title_certificate = ''
            }
          })
        }
      })
    },
    // 级联选择器递归判断children有没有值
    hasChildren(list) {
      list.forEach((item) => {
        if (item.children.length > 0) {
          this.hasChildren(item.children)
        } else {
          item.children = null
        }
      })
      return list
    },
    //工种
    getJobTradeType() {
      jobPostType({ code: 'trade_type' }).then((res) => {
        this.tradeTypeList = this.hasChildren(res.data[0].children)
        console.log(this.tradeTypeList)
      })
    },
    // 选择期望行业
    chooseTradeType(e) {
      console.log(e)
      this.form.expectPost = e[e.length - 1]
    },
    // 选择城市
    handleChange(val) {
      console.log(val)
      this.form.city = val[val.length - 1]
    },
    // 选择出生日期
    getWasBornDate(val) {
      this.form.wasBornDate = moment(val).format('YYYY-MM-DD HH:mm:ss')
      console.log(this.form.wasBornDate)
      this.form.age =
        moment(new Date()).format('YYYY') - moment(val).format('YYYY')
    },
    // 选择薪资
    getSalary(val) {
      console.log(val, '薪资')
      this.form.pay = val
    },
    // 保存信息
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.formFlag = true
        } else {
          console.log('error 基本信息验证!!')
          return false
        }
      })
      this.queryExists(this.form).then(() => {
        if (this.boo) {
          if (this.formFlag && !this.id) {
            // 新增求职信息
            this.form.jobUserId = localStorage.getItem('userId')
            // this.form.sex = this.form.sex == '男' ? 1 : 2
            console.log(this.form)
            jobInfoAdd(this.form).then((res) => {
              this.saveFlag = true
              console.log(res, '添加求职信息')
              if (res.success) {
                this.$message({
                  message: '添加成功',
                  type: 'success',
                })
                this.$refs.form.resetFields()
                // 扣除权益
                // this._subtractEquityNum()
                this.$router.push('myJobInformation')
                // setTimeout(() => {
                //   this.saveFlag = false
                //   this.$router.push('myJobInformation')
                // }, 500)
              } else {
                this.saveFlag = false
                this.$message.error('添加失败')
              }
            })
          } else if (this.formFlag && this.id) {
            // this.form.sex = this.form.sex == '男' ? 1 : 2
            // console.log(this.form)
            this.form.id = this.id
            jobInfoEdit(this.form).then((res) => {
              this.saveFlag = true
              console.log(res, '修改求职信息')
              if (res.success) {
                this.$message({
                  message: '修改成功',
                  type: 'success',
                })
                this.$refs.form.resetFields()
                setTimeout(() => {
                  this.saveFlag = false
                  this.$router.push('myJobInformation')
                }, 500)
              } else {
                this.saveFlag = false
                this.$message.error('修改失败')
              }
            })
          }
        } else {
          this.saveFlag = false
        }
      })
    },
    // 取消添加求职信息
    goOutResume() {
      this.$router.push('myJobInformation')
    },
    _subtractEquityNum() {
      let obj = {
        jobUserId: this.form.jobUserId,
        index: 4,
        subtractNum: 1,
      }
      // obj.jobVipEquityId = this.jobVipEquity.filter(i => i.code == 4)[0].name
      subtractEquityNum(obj).then((res) => {
        if (res.success) {
          setTimeout(() => {
            this.saveFlag = false
            this.$router.push('myJobInformation')
          }, 500)
        } else {
          this.$message.error('权益扣除失败，请重新发布')
        }
      })
    },
    async queryExists(record) {
      console.log('record', record)
      const newText = JSON.stringify(record)
      record = {}
      record.newText = newText
      this.text01 = ''
      this.boo = false
      await queryExists(record).then((res) => {
        if (res.success) {
          console.log(res.data)
          if (res.data.status) {
            if (Object.keys(res.data.map).length > 0) {
              // let i = 0
              for (const key in res.data.map) {
                this.text01 =
                  this.text01 + key + ' : ' + res.data.map[key] + '， '
              }
              this.text01 = this.text01.substring(0, this.text01.length - 1)
              this.boo = false
              console.log(this.text01 + 'text01')
              this.$message.close()
              this.$message.warning('文章中敏感词汇如下：  ' + this.text01)
              // this.visible = true
            } else {
              this.boo = true
              this.$message.close()
              // this.$message.success('文章中无敏感词')
            }
          } else {
            this.boo = false
            this.$message.close()
            this.$message.error('敏感字符过滤失败')
          }
        } else {
          this.$message.close()
          this.$message.error('敏感字符过滤失败') // + res.message
          this.boo = false
        }
      })
    },
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.itemName {
  padding: 0 86px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .blueBgc {
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background-color: #3f9efc;
    border-radius: 50%;
    margin-right: 10px;
  }
  b {
    font-size: 20px;
  }
}

.formBox {
  padding: 0px 26px;
  background-color: #fff;
}

.formItem,
.formItem2 {
  padding-left: 40px;
  padding-right: 40px;
}

.btnBox {
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0 20px 0;
  .el-button {
    width: 83px;
    height: 42px;
    border-radius: 2px;
    margin: 0 60px;
  }
}

.essentialInformation {
  .formItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .el-form-item {
      width: 300px;
      margin-right: 50px;
    }
  }
  .formItem2 {
    .el-select {
      width: 150px;
      margin-right: 35px;
    }
    .el-cascader {
      width: 520px;
    }
  }
}

.workExperience,
.selfIntroduction {
  .el-form-item {
    ::v-deep .el-form-item__content {
      margin-left: 0 !important;
    }
  }
}

.vocationalSkills {
  padding-bottom: 20px;
  .certificateBox {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .certificateItem {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      margin-bottom: 10px;
      span {
        font-size: 14px;
        color: #666;
      }
      img {
        width: 140px;
        height: 88px;
        object-fit: contain;
      }
    }
  }
}
</style>
